@mixin input-form() {
    border-radius: rem(6) !important;
    border: 0.5px solid $raisin-black !important;
    background-color: transparent !important;

    input {
        background-color: transparent;
        font-size: rem(12);
        line-height: rem(15);
        font-weight: 400;
        color: $raisin-black;

        &::placeholder {
            color: $raisin-black;
        }
    }
}

.a-inputCustom {
    $root: &;
    position: relative;
    width: 100%;

    &-disabled {
        border-radius: 8px !important;
        background-color: #f5f5f5 !important;

        #{$root}_input {
            background-color: #f5f5f5 !important;
        }
    }

    &_input {
        border: 0.7px solid #b9bdc1 !important;
        border-radius: 8px !important;
        padding: 6px 28px 6px 8px !important;
        height: 48px;
        margin-top: 4px;
        margin-bottom: 4px;
        background-color: $white !important;

        input {
            font-size: 16px;
            width: 100%;
            height: 100%;
            outline: none;
            border: 0;
            color: #650035;
            font-weight: 700;

            &::placeholder {
                color: #b9bdc1;
                font-weight: 700;
            }
        }

        &-form {
            height: rem(45);
            @include input-form();

            &-otp {
                @include input-form();
                height: rem(60) !important;
                width: rem(55) !important;
                padding: 6px !important;

                input {
                    font-size: rem(30);
                    line-height: rem(42);
                    text-align: center;
                }
            }
        }

        &-error {
            border-color: $fire-engine-red !important;
        }
    }

    &-hidden {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
    }

    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input:focus {
        outline: none;
    }
}
