// Text Colors
$white: rgb(255, 255, 255); // #ffffff
$black: rgb(0, 0, 0); // #000000
$fire-engine-red: rgb(198, 30, 30); //#c61e1e
$white-smoke: rgb(244, 246, 250); //#f4f6fa
$davys-grey: rgb(89, 89, 89); //#595959
$platinum: rgb(222, 224, 234); // #dee0ea
$isabelline: rgb(237, 237, 237); //#ededed
$raisin-black: rgb(34, 34, 34); //#222222
$prussian-blue: rgb(6, 43, 75); //#062b4b
$claret: rgb(120, 12, 62); //#780c3e
$smoky-black: rgb(10, 10, 10); //#0a0a0a
$snow: rgb(251, 247, 249); //#fbf7f9

// Overlays
$black-088: rgba($black, 0.88);
$raisin-black-07: rgba($raisin-black, 0.7);
$raisin-black-08: rgba($raisin-black, 0.8);

$white-04: rgba($white, 0.4);

// Linears
