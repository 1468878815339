.a-icon {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;

    &-16 {
        height: rem(16);
        width: rem(16);
    }

    &-24 {
        height: rem(24);
        width: rem(24);
    }

    &-32 {
        height: rem(32);
        width: rem(32);
    }

    &-full {
        width: 100%;
        aspect-ratio: 1;
    }

    &-rightArrow {
        background-image: url('~assets/icons/ic_rightArrow.svg');
    }

    &-leftArrow {
        background-image: url('~assets/icons/ic_rightArrow.svg');
        transform: rotate(180deg);
    }

    &-arrowDownBound {
        background-image: url('~assets/icons/ic_arrowDownBound.svg');
    }

    &-warning {
        background-image: url('~assets/icons/ic_warning.svg');
    }
}
