.m-pulldown {
    $root: &;
    position: relative;
    margin-top: 4px;
    margin-bottom: 4px;
  
    &-disabled {
        #{$root}__placeholder {
            opacity: 0.3;
        }
    }
  
    &-error {
        #{$root}__control {
            border: 0.7px solid #dc1f18;
  
            &:hover {
                border: 0.7px solid #dc1f18;
            }
        }
    }
  
    /* Customize */
    &__input {
        font-size: 16px !important;
        font-family: inherit !important;
  
        &-container {
            margin: 0 !important;
            padding: 0 !important;
        }
    }
  
    &-menu {
        z-index: 2 !important;
    }
  
    &__control {
        &--is-focused {
            box-shadow: none !important;
        }
  
        // .a-icon-arrowDown {
        //   transition: all 0.25s ease-out;
        // }
  
        // &--menu-is-open {
        //   .a-icon-arrowDown {
        //     transform: rotate(180deg);
        //   }
        // }
    }
  
    &__single-value {
        display: flex;
        align-items: center;
    }
  
    &__singleValue {
        display: flex;
        align-items: center;
  
        &__text {
            font-size: 16px;
            line-height: 21px;
            color: #f0f0;
        }
    }
  
    &__indicators {
        padding-left: 0 !important;
        padding-right: 8px !important;
    }
  
    &__indicator-separator {
        display: none;
    }
  
    &__value-container {
        font-size: 16px;
        padding-left: 8px !important;
        padding-right: 0 !important;
    }
  
    &__menu {
        &-list {
            border-radius: 8px !important;
            padding: 12px 8px;
            max-height: 200px !important;
        }
    }
  
    &__option {
        background-color: #fff !important;
  
        &:hover,
        &--is-selected {
            background-color: #650035 !important;
            color: #fff;
        }
    }

    &_error {
        display: flex;
        align-items: center;
    }
}
