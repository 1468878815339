.o-modal {
    $root: &;
    height: 100%;
    outline: 0;
    position: relative;
    width: 100%;

    &:focus {
        outline: 0;
    }

    &-notify {
        max-width: rem(380);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    &_main {
        align-items: center;
        display: flex;
        height: 100%;
        max-width: rem(1366);
        margin-left: auto;
        margin-right: auto;
        overflow: hidden;
        padding: rem(12);
        width: 100%;

        &-small {
            max-width: rem(565);
        }
    }

    &_wrapper {
        border-radius: rem(7);
        background-color: $white;
        color: $white;
        display: flex;
        flex-direction: column;
        padding: rem(24);
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 103;

        &-sharedFolder {
            max-width: rem(580);
            margin-left: auto;
            margin-right: auto;
        }
    }

    &-success {
        #{$root}_wrapper {
            padding: 0;
            border-radius: rem(27);
        }
    }

    &-control {
        .o-modal {
            &_wrapper {
                width: 60%;
                margin-left: auto;
                margin-right: auto;
                padding: rem(26) rem(32);
            }
        }
    }

    &-propmt {
        #{$root}_main {
            max-width: rem(546);
            
            #{$root}_wrapper {
                border-radius: rem(20);
            }
        }
    }

    &_body {
        flex: 1;
    }

    &_button {
        @include adjust-flex();
        gap: rem(15);
        margin-top: rem(20);

        button {
            margin-top: 0;
        }
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}

.ReactModal__Overlay {
    background: rgba(34, 34, 34, 0.3);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    position: fixed;
    transform: translateY(-100%);
    transition: all 500ms ease-in-out;
    z-index: $z-modal;

    &::after {
        background: rgba(34, 34, 34, 0.3);
        content: '';
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 102;
    }

    &--open {
        overflow: hidden;
    }

    &--after-open {
        opacity: 1;
        transform: translateY(0);
    }

    &--before-close {
        opacity: 0;
        transform: translateY(-100%);
    }
}
