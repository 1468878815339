.t-formInformation {
    position: relative;
    width: 100%;

    &_form {
        width: 100%;

        @include tablet-down {
            padding: 0;
            box-shadow: none;
        }
    }

    &_heading {
        font-size: rem(24);
        font-weight: 700 !important;
        margin-bottom: rem(8) !important;
        text-align: center;
        color: $claret;

        @include mobile-down {
            font-size: rem(18) !important;
        }
    }

    &_label {
        color: $smoky-black;
        font-weight: bold;
        font-size: rem(14);
    }

    &_link {
        text-align: center;
        display: block;
        margin-top: rem(12);
        margin-bottom: rem(12);
        color: $claret;
        font-style: italic;
        text-decoration: underline;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;

        @include mobile-down {
            font-size: rem(12);
        }
    }

    &_button {
        width: rem(200);
        margin: rem(32) auto;
    }

    &_logo {
        width: rem(60);
        margin: rem(12) auto;
    }

    &_qr {
        width: rem(200);
        margin: rem(16) auto;
    }

    &_textRedirect {
        text-align: center;
        color: $smoky-black;
    }

    &_popup {
        &-desktop {
            @include tablet-down {
                display: none;
            }
        }

        &-mobile {
            @include tablet-up {
                display: none;
            }
        }
    }

    &_footer {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: rem(20);
        margin-bottom: rem(26);

        &_link {
            text-align: center;
            display: block;
            margin-top: rem(12);
            margin-bottom: rem(12);
            color: $claret;
            font-style: italic;
            text-decoration: underline;
            width: fit-content;

            @include mobile-down {
                font-size: rem(12);
                margin-top: rem(4);
                margin-bottom: rem(4);
            }
        }

        &_divider {
            position: relative;
            width: rem(1);
            height: rem(20);
            background-color: $davys-grey;
        }
    }
}
