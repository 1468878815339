.a-button {
    border-radius: rem(9) !important;
    background-color: $claret !important;
    padding: rem(12) rem(36);
    color: $white;
    font-size: rem(16);
    line-height: rem(17);
    font-style: normal;
    font-weight: 700;
    width: 100%;
    letter-spacing: 0.8px;
    height: fit-content !important;

    .tfcbtn-icon {
        margin-top: 0;
    }

    &:disabled {
        pointer-events: none;
        opacity: 0.5;
    }

    &:hover {
        opacity: 0.8;
        transition: 0.25s all ease;
    }

    &-widthFit {
        width: fit-content;
    }

    &-w150 {
        width: rem(150);
    }

    &-w255 {
        width: rem(255);
    }

    &-transparent {
        background: transparent !important;
        color: $black-088 !important;
        flex-direction: row !important;
    }

    &-uppercase {
        text-transform: uppercase !important;
    }

    &-lowercase {
        text-transform: lowercase !important;
    }
}
