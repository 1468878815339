.a-loading {
    $root: &;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: rem(10);
    margin-top: rem(10);
    width: 100%;
    background-color: transparent;

    &-small {
        margin: 0;
    }

    .a-icon {
        height: rem(30);
        width: rem(30);
    }

    &-fullScreen {
        height: 100vh;
        left: 0;
        margin: 0;
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 10;
        background-color: rgba($black, 0.7);

        .a-icon {
            height: rem(60);
            width: rem(60);
        }
    }

    &-filled {
        background-color: $white;
    }

    &_wrapper {
        align-items: center;
        background-color: transparent;
        display: flex;
        justify-content: center;

        .a-text {
            margin-left: rem(8);
        }
    }

    &_tail {
        animation: tail 1s infinite linear;
        background-image: linear-gradient($white, $white), conic-gradient($white, $claret);
        background-origin: border-box;
        background-clip: content-box, border-box;
        border: double 0.25em transparent;
        border-radius: 100%;
        height: rem(42);
        overflow: hidden;
        position: relative;
        width: rem(42);

        &-small {
            width: rem(24);
            height: rem(24);
        }
    }

    &-white {
        #{$root}_tail {
            background-image: linear-gradient($black, $black), conic-gradient($black, $white);
        }
    }
}

@keyframes tail {
    to { transform: rotate(1turn); }
}
