.p-home {
    position: relative;
    width: 100%;
    min-height: 100vh;
    height: 100%;

    &_logo {
        padding-top: calc(20 / 1024 * 100%);
        padding-left: calc(200 / 2560 * 100%);

        @include break-max(1920px) {
            padding-left: calc(50 / 1920 * 100%);
        }

        @include max-desktop-down {
            padding-left: calc(30 / 1440 * 100%);
        }

        @include desktop-down {
            padding-top: calc(15 / 1024 * 100%);
            padding-left: calc(20 / 1440 * 100%);
        }

        @include tablet-down {
            padding-top: rem(20);
            padding-bottom: rem(20);
            padding-left: 0;
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
        }

        &-img {
            object-fit: contain;
            background-repeat: no-repeat;
            width: calc(180 / 1440 * 100%);
            height: calc(64 / 1440 * 100%);
            max-width: rem(180);
            max-height: rem(64);

            @include desktop-down {
                max-width: rem(150);
                max-height: rem(50);
            }

            @include break-max(1024px) {
                width: rem(120);
                height: rem(40);
            }

            @include tablet-down {
                width: rem(180);
                height: rem(62);
            }

            @include mobile-down {
                width: rem(140);
                height: rem(46);
            }
        }
    }

    &_form {
        width: 100%;
    }
}
