.o-modalOTP {
    max-width: rem(508);

    &_heading {
        font-size: rem(24);
        font-weight: 700;
        margin-bottom: rem(32) !important;
        margin-top: rem(16) !important;
        text-align: center;
        color: $claret;

        @include tablet-down {
            margin-bottom: rem(12) !important;
        }

        @include mobile-down {
            font-size: rem(18);
        }
    }

    &_label {
        font-weight: 500;
    }

    &_wrapInput {
        margin-top: rem(16) !important;
    }

    &_link {
        font-family: inherit;
    }

    &_error {
        display: block;
        margin-top: rem(8);
        margin-bottom: rem(8);
        color: $fire-engine-red;
        font-size: rem(12);
    }

    &_reSendBtn {
        padding: 0 !important;
        cursor: pointer;
    }

    &_description {
        margin-top: rem(30);
        margin-bottom: rem(30);

        @include tablet-down {
            margin-top: rem(20);
            margin-bottom: rem(20);
        }
    }

    &_button {
        margin-left: auto;
        margin-right: auto;
    }

    &_timeExpired {
        color: $claret;
        @include adjust-flex();
        margin-top: rem(30);
        margin-bottom: rem(30);

        @include tablet-down {
            margin-top: rem(20);
            margin-bottom: rem(20);
        }

        @include mobile-down {
            color: $claret;
        }

        &-duration {
            margin-left: rem(5);
            width: rem(60);
        }
    }

    &_wrapLink {
        margin-top: rem(30);
        @include adjust-flex();

        &_text {
            margin-right: rem(3);
        }
    }

    &_reSendBtn {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
