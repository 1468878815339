.m-otpInput {
    $root: &;
    position: relative;

    @include mobile-down {
        margin-bottom: rem(20);
    }

    &_wrapper {
        @include adjust-flex();
        margin-top: rem(20);
        gap: rem(37);
    }

    .a-inputCustom {
        width: auto;
    }

    &_input {
        height: rem(60);
        border-radius: rem(6) !important;
        border: 0.5px solid $raisin-black !important;
        background-color: transparent;

        input {
            background-color: transparent;
            font-size: rem(12);
            line-height: rem(15);
            font-weight: 400;
            font-style: italic;
            color: $raisin-black;
            font-family: inherit;
        }
    }

    .a-input {
        margin-left: rem(20);
        margin-right: rem(20);

        @include tablet-down {
            margin-left: rem(4);
            margin-right: rem(4);
        }
    }

    &_error {
        color: $fire-engine-red;
        margin-top: rem(8);
        text-align: center;
    }
}
