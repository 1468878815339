.a-checkbox {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;

    &_radio {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        cursor: pointer;
        position: relative;
        width: 24px;
        height: 24px;
        flex-shrink: 0;

        &-input {
            position: absolute;
            box-sizing: border-box;
            padding: 0;
            margin: 0;
            outline: none;
            border: 0;
            z-index: 1;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
            opacity: 0;
            display: block;
        }

        &-inner {
            box-sizing: border-box;
            width: 100%;
            height: 100%;
            display: block;
            position: relative;
            background-color: #fff;
            border: 1px solid #b9bdc1;
            border-radius: 50%;

            &-checked {
                border: 0;
                background-color: #e7ccda;
            }

            &-icon {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 14px;
                height: 11px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: #780c3e;
            }
        }

        +span {
            margin-left: 8px;
            display: inline-block;
            flex: 1;
        }
    }
}
